/* Modal Container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  box-sizing: border-box;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  text-align: justify;
  padding: 15px;
  max-width: 500px;
  max-height: calc(100% - 30px); /* Aggiunto max-height */
  overflow: auto; /* Aggiunto overflow */
  border-radius: 14px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.modal img {
  border-radius: 14px;
}

/* Modal Header */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Modal Title */
.modal-header h2 {
  margin: 0;
}

/* Close Button */
.close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Modal Body */
.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

/* Modal Image */
.modal-body img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

/* Modal Description */
.modal-body p {
  margin: 0;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Close Button in Footer */
.modal-footer .btn {
  background-color: #1abc9c;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
