.masthead {
  padding-top: calc(1.5rem + 74px);
  padding-bottom: 1.5rem;
  background-color: #1abc9c;
}
.masthead .masthead-heading {
  font-size: 2.5rem;
  line-height: 2.5rem;
}
.masthead .masthead-subheading {
  font-size: 1.25rem;
}
.masthead .masthead-avatar {
  width: 18rem;
}

@media (min-width: 992px) {
  .masthead {
    padding-top: calc(4rem + 104px);
    padding-bottom: 2rem;
  }
  .masthead .masthead-heading {
    font-size: 4rem;
    line-height: 3.5rem;
  }
  .masthead .masthead-subheading {
    font-size: 1.5rem;
  }
}
