.portfolio-img {
  border-radius: 14px;
  width: 400px;
  height: 300px;
}
.portfolio-item {
  text-align: center;
}
.portfolio .portfolio-item {
  cursor: pointer;
  position: relative;
  display: block;
  max-width: 26rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.portfolio .portfolio-item .portfolio-item-caption {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: rgba(26, 188, 156, 0.9);
}
.portfolio .portfolio-item :hover {
  opacity: 1;
}
.portfolio
  .portfolio-item
  .portfolio-item-caption
  .portfolio-item-caption-content {
  font-size: 1.5rem;
}
